//import manager from '@/utils/manager'
import api from '@/utils/api'

export function accountInfo(params) {
    return api.get('wx/v1/account', {params: params})
}

export function gameList(params) {
    return api.get('wx/v1/darkxiyou/game', {params: params})
}

export function serverList(params) {
    return api.get('wx/v1/darkxiyou/server', {params: params})
}

export function roleList(params) {
    return api.get('wx/v1/darkxiyou/role', {params: params})
}

// export function payStripe(params) {
//     return api.post('stripe/pay', params)
// }
// export function productsList(params) {
//     return api.get('wx/v1/darkxiyou/products', { params:params })
// }

// export function createOrder(params) {
//     return api.post('wx/v1/jzac', params)
// }
export function payThunderSnake(params) {
    return api.post('thundersnakePay/pay', params)
}

export function payLianLian(params) {
    return api.post('lianLianPay/pay', params)
}

export function stripeMoneyList(params) {
    return api.post('stripe/moneyList', params)
}


export function pay(params) {
    // return api.post('wx/v1/fenghuo/pay', params)
    params.project_id = 'fenghuo';
    params.type = 2;
    return api.post('wx/v1/official/common/wxpay', params)
}

export function commonCreateOrder(params) {
    return api.post('wx/v1/darkxiyou/common/jzac', params)
}

//支付包支付或paypel支付
export function payMix(params) {
    // return api.post('wx/v1/fenghuo/pay/mix', params)
    return api.post('wx/v1/official_account/pay', params)
}

//  公众号微信支付
export function payWxRedirect(orderNum, userId,count) {
    // return api.post('wx/v1/fenghuo/pay/wxRedirect', { order_num: orderNum })
    // 最新版公众号微信支付
    // return api.post('wx/v1/official/common/wxpay', { order_num: orderNum, type: 1, project_id: 'fenghuo' })
    // return api.post('darkxiyou/order/pay/wx/h5', { order_num: orderNum, userId: userId  })
    return api.post('wx/v1/androdH5Pay/appletPay', {number: orderNum, userId: userId,count:count})
}

//  mycard台湾支付
export function payMycard(params) {
    return api.post('mycardPay/pay', params)
}
