//import manager from '@/utils/manager'
import api from '@/utils/api'

/**
 *  stripe支付
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function stripePayment(params) {
    return api.post('/stripe/payment', params)
}

/**
 *  Airwallex支付
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function AirwallexPayment(params) {
    return api.post('/airwallex/payment', params)
}

/**
 * 查询paypal支付所需要的userinfo
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserinfo(params) {
    return api.get('/wx/v1/comm/user-extra-info',  { params:params })
}

//根据订单号查询美元价格
export function getUsdMoney(params) {
    return api.get('/common/getUsdMoney',  { params:params })
}


/**
 * 保存paypal支付所需要的userinfo
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function saveUserinfo(params) {
    return api.post('/wx/v1/comm/save-user-info', params)
}


//payple同步下发
export function sync_order(params) {
    return api.post('/third/paypal_bank/paypal/sync_order', params)
}