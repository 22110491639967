import axios from 'axios'
import {
  Toast
} from 'vant'
import {
  getToken,
  setToken,
  removeToken
} from './auth'

const service = axios.create({
  // baseURL: process.env.VUE_APP_HOST_URL, // 部署地址 
  baseURL: "https://wx.playhy.com" //正式地址
  // baseURL: "https://beta-api.hzarcticwolf.com/" //测试地址
  // baseURL: "http://www.cs4.com:8080"
  // baseURL: "http://api.mfms.xhppgames.cc"
  //   baseURL: "http://localhost"
})

service.interceptors.request.use(
  config => {
    // config.headers['Accept'] = 'application/json'
    //   if (getToken()) {
    //       config.headers['Authorization'] = getToken()
    //   }
    const current_url = window.location.href
    //   区分打金和其他
    if (current_url.indexOf("/#/gift/darkxiyou") != -1) {
      if (getToken('darkxiyou_')) config.headers['Authorization'] = getToken('darkxiyou_')
    } else if (current_url.indexOf("/#/gift/ghlc") != -1) {
      if (getToken('ghlc_')) config.headers['Authorization'] = getToken('ghlc_')
    } else if (current_url.indexOf("/#/gift/rxhj") != -1) {
      if (getToken('rxhj_')) config.headers['Authorization'] = getToken('rxhj_')
    } else if (current_url.indexOf("/#/gift/fkjlb") != -1) {
      if (getToken('fkjlb_')) config.headers['Authorization'] = getToken('fkjlb_')
    } else if (current_url.indexOf("/#/gift/dscj") != -1) {
      if (getToken('dscj_')) config.headers['Authorization'] = getToken('dscj_')
    }else if (current_url.indexOf("/#/gift/sgtl") != -1) {
      if (getToken('sgtl_')) config.headers['Authorization'] = getToken('sgtl_')
    } else {
      if (getToken()) config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      // setToken(response.headers.authorization)
      const current_url = window.location.href
      //   区分打金和其他
      if (current_url.indexOf("/#/gift/darkxiyou") != -1) {
        setToken(response.headers.authorization, 'darkxiyou_')
      } else if (current_url.indexOf("/#/gift/ghlc") != -1) {
        setToken(response.headers.authorization, 'ghlc_')
      } else if (current_url.indexOf("/#/gift/rxhj") != -1) {
        setToken(response.headers.authorization, 'rxhj_')
      } else if (current_url.indexOf("/#/gift/fkjlb") != -1) {
        setToken(response.headers.authorization, 'fkjlb_')
      }else if (current_url.indexOf("/#/gift/dscj") != -1) {
        setToken(response.headers.authorization, 'dscj_')
      }else if (current_url.indexOf("/#/gift/sgtl") != -1) {
        setToken(response.headers.authorization, 'sgtl_')
      } else {
        setToken(response.headers.authorization)
      }
    }
    if (response.data.code === 401) {
      // removeToken()
      const current_url = window.location.href
      //   区分打金和其他
      if (current_url.indexOf("/#/gift/darkxiyou") != -1) {
        removeToken('darkxiyou_')
      } else if (current_url.indexOf("/#/gift/ghlc") != -1) {
        removeToken('ghlc_')
      } else if (current_url.indexOf("/#/gift/rxhj") != -1) {
        removeToken('rxhj_')
      } else if (current_url.indexOf("/#/gift/fkjlb") != -1) {
        removeToken('fkjlb_')
      }else if (current_url.indexOf("/#/gift/dscj") != -1) {
        removeToken('dscj_')
      }else if (current_url.indexOf("/#/gift/sgtl") != -1) {
        removeToken('sgtl_')
      } else {
        removeToken()
      }
      // removeUsername()
      // Toast.fail('用户未登录或登录信息已失效')
      Toast.fail('请重新登录')
      return Promise.reject(response.data)
    }
    if (response.data.code !== 0) {
      if (response.data.code != 504) {
        Toast.fail(response.data.message)
      }
      return Promise.reject(response.data)
    }
    return Promise.resolve(response.data.data)
  },
  error => {
    let message = error.message
    if (error.response.status === 401) {
      // removeToken()
      const current_url = window.location.href
      //   区分打金和其他
      if (current_url.indexOf("/#/gift/darkxiyou") != -1) {
        removeToken('darkxiyou_')
      } else if (current_url.indexOf("/#/gift/ghlc") != -1) {
        removeToken('ghlc_')
      } else if (current_url.indexOf("/#/gift/rxhj") != -1) {
        removeToken('rxhj_')
      } else if (current_url.indexOf("/#/gift/fkjlb") != -1) {
        removeToken('fkjlb_')
      }else if (current_url.indexOf("/#/gift/dscj") != -1) {
        removeToken('dscj_')
      }else if (current_url.indexOf("/#/gift/sgtl") != -1) {
        removeToken('sgtl_')
      } else {
        removeToken()
      }
      Toast.fail('请重新登录')
      // let second = 2
      // const timer = setInterval(() => {
      //   second--
      //   if (second <= 0) {
      //     clearInterval(timer)
      //     Toast.clear()
      //     location.reload()
      //   }
      // }, 1000)
    }
    switch (error.response.status) {
      case 401: {
        message = '请重新登录'
        // removeToken()
        const current_url = window.location.href
        //   区分打金和其他
        if (current_url.indexOf("/#/gift/darkxiyou") != -1) {
          removeToken('darkxiyou_')
        } else if (current_url.indexOf("/#/gift/ghlc") != -1) {
          removeToken('ghlc_')
        } else if (current_url.indexOf("/#/gift/rxhj") != -1) {
          removeToken('rxhj_')
        } else if (current_url.indexOf("/#/gift/fkjlb") != -1) {
          removeToken('fkjlb_')
        }else if (current_url.indexOf("/#/gift/dscj") != -1) {
          removeToken('dscj_')
        }else if (current_url.indexOf("/#/gift/sgtl") != -1) {
          removeToken('sgtl_')
        } else {
          removeToken()
        }
        break
      }
      case 422:
        // message = ''
        message = error.response.data.message
        // if (error.response.data && error.response.data.errors) {
        //   for (const key in error.response.data.errors) {
        //     const errors = error.response.data.errors[key]
        //     for (const index in errors) {
        //       message += errors[index] + '<br>'
        //     }
        //   }
        // }
        break
      default:
        message = '请求错误，请联系客服!'
        break
    }
    if (error.response.status != 504) {
      Toast.fail(message)
    }
    return Promise.reject(error)
  }
)

export default service