export function isWechat() {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/micromessenger/i)) {
    return true;
  } else {
    return false;
  }
}

export function parseQueryString(url) {
  let params = url.split('&')
  let ret = {}
  params.forEach(param => {
    const keyVal = param.split('=')
    ret[keyVal[0]] = keyVal[1]
  })
  return ret
}

/**
 *
 *获取平台
 * @export
 * @returns
 */
 export function isSysName() {
  var ua = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod/.test(ua) || /macintosh/.test(ua)) {
    return 'ios'
  } else if (/android/.test(ua)) {
    return 'android'
  }
}
